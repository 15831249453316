@font-face {
  font-family: "RasmusAndersson-Inter";
  src: local("RasmusAndersson-Inter"),
    url("./fonts/1593443255805-Inter-Medium.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}


body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */

  padding: 0;
  box-sizing: border-box;
  /* background: rgb(42, 47, 131); */
  /* background: radial-gradient(
    circle,
    rgba(42, 47, 131, 0) 0%,
    rgba(131, 43, 103, 0.30575980392156865) 100%
  ); */
  background: linear-gradient(
    to right,
    #f3c6e6,
    #f3dcee 35%,
    #fce2f3 70%,
    #f8aee2
  );
  font-family: "RasmusAndersson-Inter";
}
.goKBZv > h1 {
  color: #2a2f83 !important;
}

.base-color {
  color: #2a2f83;
}

.bg-base-color {
  background-color: #2a2f83;
}
.rbc-event {
  background-color: transparent !important; /* Override default overlay background */
}

.app-color {
  color: #832b67;
}

.bg-app-color {
  background-color: #832b67;
}

.app-desc-txt-color {
  color: #797a7c;
}

.letter-space {
  letter-spacing: 1px;
}

.remove-oultine {
  outline: none;
}

.remove-oultine:focus {
  outline: none;
}

.remove-oultine::placeholder {
  color: #2a2f83;
  padding: 5px;
}

/* Extra Small (xs) */

/* Small (sm) */
.font-sm {
  font-size: small;
  /* background-color: red; */
}

@media (min-width: 576px) {
}

/* Medium (md) */
@media (min-width: 768px) {
  .font-sm {
    font-size: 12px;
  }
 
}

/* Large (lg) */
@media (min-width: 992px) {
  .font-sm {
    font-size: 20px;
  }

  
}

/* Extra Large (xl) */
@media (min-width: 1200px) {
  .font-xl {
    font-size: 22px;
  }
 
}

.pointer {
  cursor: pointer;
}
.ant-table-container .ant-table-thead > tr > th {
  text-wrap: nowrap !important;  /* Prevent header text from wrapping */
}

.question-options:hover {
  background-color: #f0f0f0;
}


.profile-list {
  display: flex;
  flex-direction: row; /* Ensures horizontal layout */
  overflow-x: auto; /* Enables horizontal scrolling */
  padding: 16px;
  gap: 16px;
  scrollbar-width: none; /* Hides scrollbar in Firefox */
}

.suggestion-list, .question-modal, .hide-scrollbar{
  scrollbar-width: none;
}
.suggestion-item:hover{
  background-color: #e1e2e6;
}

.profile-list::-webkit-scrollbar,
.suggestions-list::-webkit-scrollbar,
.question-modal::-webkit-scrollbar,
.hide-scrollbar::-webkit-scrollbar {
  display: none; 
}

.profile-view {
  flex: 0 0 auto;
  position: relative;
  width: 330px;
  height: 290px;
  border-radius: 8px;
  overflow: hidden;

}

.profile-view img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;
}

.status {
  align-self: flex-end;
  font-size: 14px;
  font-weight: bold;
  padding: 4px 8px;
  border-radius: 10px;
  cursor: pointer;
}

.status.available {
  background-color: #28a745; /* Green for available */
}

.name {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}

.no-more-data,
.loader {
  text-align: center;
  font-size: 16px;
  margin: 16px auto;
}


.my-button {
  padding: 10px 20px;
  background: none;
  color: #000;
  border: 1px solid #007bff;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease; 
}


.base-hover:hover{
  background-color: #333bbb ;
}

.app-hover:hover{
  background-color: #bf288f;
}

.back-btn:hover{
  color: #000 !important;
}

.question-checkbox{
  accent-color: #2a2f83 !important;
}
.prfoile-list-div{
  height: 300px;
}


/* @keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%); 
  }
}
 */
