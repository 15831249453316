 .guard-photo-slider {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .guard-list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
  
  .guard-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    width: 150px;
    text-align: center;
  }
  
  .guard-card img {
    width: 100%;
    height: auto;
  }
  
  
  .search-input {
    color: #767e83; 
    font-size: 16px; 
    opacity: 1; 
    font-weight: 600;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  }
  
  .search-input input::placeholder {
    color: #767e83; 
    font-size: 14px; 
    opacity: 1; 
    font-weight: 600;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  }
  
  
  .custom-dropdown .ant-select-item {
    padding: 10px 15px !important;  /* Padding */
    /* font-size: 14px;   */
    color: #333;  /* Text color */
    /* transition: background 0.3s ease; */
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    
  }
  
  .custom-dropdown .ant-select-item:hover {
    background: #d4d3d3 !important; 
    cursor: pointer;
  }
  
  .custom-dropdown .ant-select-item-option-selected {
    background: #d4d3d3 !important; 
  }
  .custom-dropdown .ant-select-item-option-active {
    background: #d4d3d3 !important;  /* Custom background */
    font-weight: bold;
    /* transition: background 0.3s ease; */
  }
  
  .custom-dropdown {
    border-radius: 0 !important;
    padding: 0;

  }

  .custom-arrow.next {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #2a2f83;
    color: #fff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    position: absolute;
    right: -28px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 10;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  
  .custom-arrow.prev {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #2a2f83;
    color: #fff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    position: absolute;
    left: -28px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 10;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  

.custom-date-picker .ant-picker-input,
.custom-time-picker .ant-picker-input {
  padding: 10px;
  font-size: 16px;
  border: #2a2f83 !important;
  outline: #2a2f83;
}


@media (max-width: 768px) {
   
    .profile-padding{
      padding: 0px !important;
    }
    .prfoile-list-div{
      height: 180px !important;
    }
    .guard-show{
      width: 190px !important;
    }
    
    .question-modal-content{
      padding: 10px !important;
    }
    .quesion-text{
      font-size: 18px !important;
    }
    .options-text{
      font-size: 16px !important;
    }
    .search-div{
      margin: 0px 2% !important;
    }
    .custom-arrow{
      display: none !important;
    }
    .lastname-margin{
      margin-top: 10px;
    }
    .profile-card{
      margin-top: 0px !important;
    }
    .contact-field-space{
      width: 80% !important;
    }
    .ant-picker {
      width: 100%;
  }

  .custom-date-picker, .custom-time-picker {
      flex: 1 0 100%; /* Stack vertically */
      margin-bottom: 10px; /* Space between fields */
  }
  }
  